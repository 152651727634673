<template>
  <!-- About Section Start -->
  <div
    class="cta-area background-img-c overlay2 mb-2"
    style=""
  >
    <div class="container">
      <div class="col-md-10 offset-md-1">
        <div class="cta-content cuadro_azul">
          <p style="color: #ff5e13;">
            A propos
          </p>
          <h3>Pourquoi choisir eBDFRET ?</h3>
          <p style="color: #afb5bb;">
            L'objectif de eBDFRET est d'abord d’être la plateforme de référence en matière de publication d'offre de transports pour les Affréteurs ensuite la mise à la disposition des transporteurs ou sociétés de transports, des offres de transports proposées par les affréteurs et enfin la réduction des trajets à vide pour les Transporteurs pour permettre aux transporteurs d'augmenter leur rentabilité en recevant des offres de livraisons au moment où ils en ont besoin.
          </p>
          <div class="row mt-4">
            <div>
              <a
                class="boxed-btn text-center"
                href="#"
                style="color: white; cursor: pointer;"
                aria-label="Book a demonstration"
              >
                En savoir plus
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- About Section End -->
</template>
<script>
export default {
  name: 'About',
}
</script>
