<template>

  <div>
    <banner />
    <feature-section />
    <transport-section />
    <about />
    <div class="section-title pb-4">
      <h2>Offres de fret en temps réels</h2>
    </div>
    <div class="container px-5 py-1">
      <RealTimeOffer />
    </div>

    <fret-tables />
    <actualite />
  </div>

</template>

<script>
import FeatureSection from '@/layouts/SiteLayout/home/FeatureSection.vue'
import TransportSection from '@/layouts/SiteLayout/home/TransportSection.vue'
import About from '@/layouts/SiteLayout/home/About.vue'
import RealTimeOffer from '@/layouts/SiteLayout/home/RealTimeOffer.vue'
import Actualite from '@/layouts/SiteLayout/home/Actualite.vue'
import Banner from '@/layouts/SiteLayout/home/Banner.vue'
import FretTables from '@/layouts/SiteLayout/espace_transporteur/FretTables.vue'

export default {
  name: 'Home',
  components: {
    FeatureSection,
    TransportSection,
    About,
    Banner,
    RealTimeOffer,
    Actualite,
    FretTables,
  },

}
</script>
